import React, { useEffect, useState } from "react";
import { withRouter } from "react-router-dom";
import {
  replaceUrlLanguage,
  replaceUrlLanguageForSitecore,
  useGlobalLanguageRedirect,
} from "../../../../utils/useGlobalLanguageRedirect";

// use placeholder procotol in order to create URL objects without a base URL on SSR
const PLACEHOLDER_BASE_URL = "thismessage:/";

const LanguageSelector = ({ availableLanguages, match }) => {
  useGlobalLanguageRedirect();

  const [isDropdownOpen, setIsDropdownOpen] = useState(false);

  // current relative path of page from react router - present on SSR
  const url = new URL(match.url, PLACEHOLDER_BASE_URL);
  const isSitecore = url.searchParams.has("sc_mode");

  const languageText = (() => {
    if (isSitecore) {
      return availableLanguages.find(
        (language) =>
          language.fields["Regional Iso Code"].value ===
          url.searchParams.get("sc_lang"),
      )?.fields.displayName.value;
    }

    return (
      availableLanguages.find(
        (language) =>
          language.fields.cochlearLanguageCode.value.toLowerCase() ===
          match.params.urlLang,
      )?.fields.displayName.value || "English"
    );
  })();

  useEffect(() => {
    const handleClickOutside = () => setIsDropdownOpen(false);

    window.addEventListener("click", handleClickOutside);

    return () => window.removeEventListener("click", handleClickOutside);
  }, []);

  const handleToggleDropdown = (evt) => {
    setIsDropdownOpen((prev) => !prev);
    evt.nativeEvent.stopImmediatePropagation();
  };

  return (
    <div className="language-container">
      <div className="dropdown dd-language right-md">
        <button
          className="btn btn-default dropdown-toggle uk btn-no-arrow"
          onClick={handleToggleDropdown}
        >
          {languageText}
          <span className="caret"></span>
        </button>

        {isDropdownOpen ? (
          <ul className="countries_list dropdown-menu">
            {availableLanguages.map((language) => {
              const href = isSitecore
                ? replaceUrlLanguageForSitecore(
                    match.url,
                    language.fields["Regional Iso Code"].value,
                  )
                : replaceUrlLanguage(
                    match.url,
                    language.fields.cochlearLanguageCode.value,
                    language.fields.cochlearLanguageCode.value === "en", // forceEN
                  );

              return (
                <li key={language.name}>
                  <a href={href}>
                    <span>{language.fields.displayName.value}</span>
                  </a>
                </li>
              );
            })}
          </ul>
        ) : null}
      </div>
    </div>
  );
};

export default withRouter(LanguageSelector);
