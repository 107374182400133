import { useEffect } from "react";

const FORCE_EN_KEY = "forceEN";

// use placeholder procotol in order to create URL objects without a base URL on SSR
const PLACEHOLDER_BASE_URL = "thismessage:/";
// part of the placeholder to remove from the final url
const PLACEHOLDER_PROTOCOL = "thismessage:";

/**
 * Converts browser language to a language code that is supported on a global url i.e. `/global/*`
 * @param {string} browserLanguage Browser language code
 * @returns Language code to use in global url
 */
export const mapBrowserLanguageToGlobalUrlLanguage = (browserLanguage) => {
  // exceptions
  if (["no", "nb", "nb-no", "nn-no"].includes(browserLanguage.toLowerCase())) {
    return "no";
  }

  if (browserLanguage.toLowerCase() === "pt-br") {
    return "pt-br";
  }

  if (["zh", "zh-cn", "zh-sg"].includes(browserLanguage.toLowerCase())) {
    return "zh-hans";
  }

  if (["zh-hk", "zh-mo", "zh-tw"].includes(browserLanguage.toLowerCase())) {
    return "zh-hant";
  }

  const languageCodes = [
    "ar",
    "az",
    "bg",
    "cs",
    "da",
    "de",
    "el",
    // en is not included, because en is treated as a "default" case to not allow further redirects
    "es",
    "et",
    "fa",
    "fi",
    "fr",
    "he",
    "hr",
    "hu",
    "id",
    "is",
    "it",
    "ja",
    "ka",
    "kk",
    "ko",
    "lt",
    "lv",
    "mk",
    "ms",
    "nl",
    "pl",
    "pt",
    "ro",
    "ru",
    "sk",
    "sl",
    "sq",
    "sr",
    "sv",
    "th",
    "tr",
    "uk",
    "uz",
    "vi",
  ];

  for (const languageCode of languageCodes) {
    if (browserLanguage.startsWith(languageCode)) {
      return languageCode;
    }
  }

  return "en";
};

/**
 * Replaces the language in a global URL.
 * @param {string} url Relative URL (without host to support SSR)
 * @param {string} language New language code
 * @param {boolean} forceEN Whether or not to force EN after redirecting
 * @returns New relative URL with language replaced
 */
export const replaceUrlLanguage = (url, language, forceEN = false) => {
  const newPathname = url.replace(
    /\/global\/[^/]{2,}(\/|$)/,
    `/global/${language}$1`,
  );

  const urlObj = new URL(newPathname, PLACEHOLDER_BASE_URL);

  if (forceEN) {
    urlObj.searchParams.set(FORCE_EN_KEY, "true");
  } else {
    urlObj.searchParams.delete(FORCE_EN_KEY);
  }

  return urlObj.toString().replace(PLACEHOLDER_PROTOCOL, "");
};

/**
 * Replaces the language in a Sitecore URL.
 * @param {string} url Relative URL (without host to support SSR)
 * @param {string} language New language code
 * @returns New relative URL with language replaced
 */
export const replaceUrlLanguageForSitecore = (url, language) => {
  const urlObj = new URL(url, PLACEHOLDER_BASE_URL);
  urlObj.searchParams.set("sc_lang", language);
  return urlObj.toString().replace(PLACEHOLDER_PROTOCOL, "");
};

/**
 * If on `/global/en` page and browser language is different, attempt to redirect to matching language page
 */
export const useGlobalLanguageRedirect = () => {
  // can safely use window.location inside useEffect
  useEffect(() => {
    const params = new URLSearchParams(window.location.search);

    if (
      // not sitecore editing
      !params.has("sc_mode") &&
      // only on global and en - default language
      window.location.pathname.toLowerCase().startsWith("/global/en") &&
      params.get(FORCE_EN_KEY) !== "true" &&
      navigator.language &&
      !navigator.language.startsWith("en")
    ) {
      const newLanguage = mapBrowserLanguageToGlobalUrlLanguage(
        navigator.language,
      );

      // don't redirect to en - will cause a loop
      // en treated as default if no language match is found
      if (newLanguage !== "en") {
        window.location.replace(
          replaceUrlLanguage(window.location.href, newLanguage),
        );
      }
    }
  }, []);
};
